const PASSENGER_TYPES = {
  ADT: 'ADULT',
  CHD: 'CHILD',
  INF: 'INFANT',
  ADT_SHORT: 'ADT',
  CHD_SHORT: 'CHD',
  CNN_SHORT: 'CNN',
  INF_SHORT: 'INF',
  NEG: 'NEG'
}

export const FARE_PASSENGER_TYPES = {
  'general.fares.adt': ['ADT', 'NEG'],
  'general.fares.chd': ['CHD', 'CNN', 'CNE'],
  'general.fares.inf': ['INF', 'INE']
}

const PASSENGER_TYPE_VALIDATIONS = {
  ADT: {
    MIN_AGE: 12,
    MAX_AGE: false
  },
  CHD: {
    MAX_AGE: 12,
    MIN_AGE: 2
  },
  CNN: {
    MAX_AGE: 12,
    MIN_AGE: 2
  },
  INF: {
    MIN_AGE: false,
    MAX_AGE: 2
  },
  undefined: {
    MIN_AGE: false,
    MAX_AGE: false
  }
}

export { PASSENGER_TYPES, PASSENGER_TYPE_VALIDATIONS }
