/* eslint-disable import/order */
/* eslint-disable max-len */
import React from 'react'

import { Switch } from 'react-router'
import { Route, withRouter } from 'react-router-dom'
import ROUTES from 'constants/routes'
import PageContainer from 'containers/page'
import { acceptEssentialCookies } from 'helpers/cookies'
import { isUserLogged } from 'helpers/flightOffers'
import { removeLSValue, setLSValue, getLSValue } from 'helpers/localStorage'
import { saveLastCultureVisitedToLS, updateArtId } from 'helpers/utils'
import { ArPlusLoginPage } from 'pages/arplus'
import { NormalizeComponent, GlobalStylesComponent } from 'styles/index'

import NAMESPACES from './localization/constants/namespaces'
import { NoMatchPage } from './pages'
import { showChatbotByRoutes } from './server/services/chat-bot'
import { connect } from 'react-redux'
import { getLandingPageState } from 'selectors/cms/landings'
import ConfigMetaTags from 'components/configMetaTags'

class App extends React.PureComponent {
  state = {
    showCookiesMessage: false
  }

  onClosePage = event => {
    removeLSValue('referrer')
    return event
  }

  componentDidMount() {
    updateArtId(null, this.props.location)
    showChatbotByRoutes(null, this.props.location)
    this.controlEventListenerBeforeunload()
    if (!getLSValue('isCookiesAccepted')) {
      this.setState({ showCookiesMessage: true })
    }
  }

  componentDidUpdate(prevProps) {
    updateArtId(prevProps.location, this.props.location)

    showChatbotByRoutes(prevProps.location, this.props.location)

    if (this.props.location !== prevProps.location) {
      saveLastCultureVisitedToLS()
    }
  }

  controlEventListenerBeforeunload = () => {
    window.addEventListener('beforeunload', this.onClosePage)

    document.body.onclick = e => {
      if (
        e.target.tagName?.toLowerCase() === 'a' &&
        (e.target.href || '').includes(window.origin)
      ) {
        const referrer = getLSValue('referrer')

        setTimeout(() => updateArtId(null, { search: `?artid=${referrer}` }), 1000)

        return true
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onClosePage)
  }

  onCookiesButtonClick = () => {
    setLSValue('isCookiesAccepted', true)

    this.setState({
      showCookiesMessage: false
    })
  }

  handleAcceptEssentialCookies = () => {
    acceptEssentialCookies()
    setTimeout(() => {
      setLSValue('isCookiesAccepted', true)
      this.setState({
        showCookiesMessage: false
      })
    }, 3000)
  }

  renderAppContent(props, route) {
    if (route.namespaces?.includes(NAMESPACES.SUBLOS)) {
      return React.createElement(route.component, props)
    } else {
      return route.privateUrl
        ? isUserLogged()
          ? React.createElement(route.component, props)
          : React.createElement(ArPlusLoginPage, props)
        : React.createElement(route.component, props)
    }
  }

  render() {
    return (
      <>
        <ConfigMetaTags />
        {/* Global css using styled-component api */}
        <NormalizeComponent />
        <GlobalStylesComponent />
        <PageContainer
          onButtonClick={this.onCookiesButtonClick}
          acceptEssentialCookies={this.handleAcceptEssentialCookies}
          showCookiesMessage={this.state.showCookiesMessage}
        >
          <Switch>
            {Object.keys(ROUTES)
              .filter(key => !ROUTES[key].isDisabled)
              .map((key, index) => {
                const route = ROUTES[key]
                return (
                  <Route
                    key={index}
                    path={route.LANG_URL}
                    exact={route.exact}
                    render={props => this.renderAppContent(props, route)}
                  />
                )
              })}
            <Route component={NoMatchPage} />
          </Switch>
        </PageContainer>
      </>
    )
  }
}

const mapStateToProps = state => ({
  landings: getLandingPageState(state)
})

export default withRouter(connect(mapStateToProps, null)(App))
