/* eslint-disable import/no-cycle */
import NAMESPACES from 'localization/constants/namespaces'
import {
  ArPlusDashboardPage,
  ArPlusLoginPage,
  ForgottenPasswordPage,
  RecoverMembershipCode,
  ArplusLastOperationsPage,
  RegisterArPlusPage,
  PreRegisterArPlusPage,
  PaymentMilesPage,
  AccountEvaluationPage,
  TransferMilesPage,
  ElitePartnersPage,
  AccountDataPage,
  FirstAccessPage,
  SetFirstAccessPasswordPage,
  RecoverMailPage,
  ArplusCreditMilesPage,
  ArplusGiftMilesPage,
  UnsubscribeArplusPage,
  ArplusRecoverEmailPage,
  ArplusRecoverEmailConfirmationPage,
  ChangePasswordPage,
  VerifyYourAccountPage
,} from 'pages/arplus'

// Here langId is not optional, this path regex is used to redirect
// upper case langs to lowercase ones
export const forcelangPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})`

// with this regex matches languageTag format and with ? enables it as an optional parameter
export const langPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})?`

export default {
  ARPLUS_LOGIN: {
    LANG_URL: `${langPathRegex}/arplus/acceso`,
    component: ArPlusLoginPage,
    title: 'metadata.title.arplus.login',
    description: 'metadata.description.arplus.login',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    name: 'ARPLUS_HOME'
  },
  ARPLUS_DASHBOARD: {
    LANG_URL: `${langPathRegex}/arplus/mi-cuenta`,
    component: ArPlusDashboardPage,
    title: 'metadata.title.arplus.dashboard',
    description: 'metadata.description.arplus.dashboard',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_LAST_OPERATIONS: {
    LANG_URL: `${langPathRegex}/arplus/ultimas-operaciones`,
    component: ArplusLastOperationsPage,
    title: 'metadata.title.arplus.last-operations',
    description: 'metadata.description.arplus.last-operations',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_FORGOTTEN_PASSWORD: {
    LANG_URL: `${langPathRegex}/arplus/olvide-mi-clave`,
    component: ForgottenPasswordPage,
    title: 'metadata.title.arplus.forgotten-password',
    description: 'metadata.description.arplus.forgotten-password',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_FORGOTTEN_PASSWORD_CODE: {
    LANG_URL: `${langPathRegex}/arplus/contrasena-olvidada/codigo/:membershipCode`,
    component: ForgottenPasswordPage,
    title: 'metadata.title.arplus.forgotten-password',
    description: 'metadata.description.arplus.forgotten-password',
    disallowIndex: true,
    exact: true,
    header: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_RECOVER_MEMBERSHIP_CODE: {
    LANG_URL: `${langPathRegex}/arplus/recuperar-numero-de-socio`,
    component: RecoverMembershipCode,
    title: 'metadata.title.arplus.recover-membership-code',
    description: 'metadata.description.arplus.recover-membership-code',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_REGISTER: {
    LANG_URL: `${langPathRegex}/arplus/asociate`,
    component: RegisterArPlusPage,
    title: 'metadata.title.arplus.register',
    description: 'metadata.description.arplus.register',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_PRE_REGISTER: {
    LANG_URL: `${langPathRegex}/arplus/pre-registro`,
    component: PreRegisterArPlusPage,
    title: 'metadata.title.arplus.pre-register',
    description: 'metadata.description.arplus.pre-register',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_UNSUBSCRIBE_CONFIRMATION: {
    LANG_URL: `${langPathRegex}/arplus/darse-de-baja/confirmacion/:membershipCode`,
    component: UnsubscribeArplusPage,
    title: 'metadata.title.arplus.unsubscribe',
    description: 'metadata.description.arplus.unsubscribe',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_PAYMENT_MILES: {
    LANG_URL: `${langPathRegex}/arplus/comprar-millas`,
    component: PaymentMilesPage,
    title: 'metadata.title.arplus.buy-miles',
    description: 'metadata.description.arplus.buy-miles',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_ACCOUNT_EVALUATION: {
    LANG_URL: `${langPathRegex}/arplus/mi-cuenta/evaluacion`,
    component: AccountEvaluationPage,
    title: 'metadata.title.arplus.account-evaluation',
    description: 'metadata.description.arplus.account-evaluation',
    disallowIndex: true,
    exact: true,
    header: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_TRANSFER_MILES: {
    LANG_URL: `${langPathRegex}/arplus/transferir-millas`,
    component: TransferMilesPage,
    title: 'metadata.title.arplus.transfer-miles',
    description: 'metadata.description.arplus.transfer-miles',
    disallowIndex: true,
    exact: true,
    header: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_ELITE_PARTNERS: {
    LANG_URL: `${langPathRegex}/arplus/elite-members`,
    component: ElitePartnersPage,
    title: 'metadata.title.arplus.elite-partners',
    description: 'metadata.description.arplus.elite-partners',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_ACCOUNT_DATA: {
    LANG_URL: `${langPathRegex}/arplus/datos-de-mi-cuenta`,
    component: AccountDataPage,
    title: 'metadata.title.arplus.account-data',
    description: 'metadata.description.arplus.account-data',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_FIRST_ACCESS: {
    LANG_URL: `${langPathRegex}/arplus/primer-acceso`,
    component: FirstAccessPage,
    title: 'metadata.title.arplus.first-access',
    description: 'metadata.description.arplus.first-access',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_FIRST_ACCESS_SET_PASSWORD: {
    LANG_URL: `${langPathRegex}/arplus/crear-clave-primer-acceso`,
    component: SetFirstAccessPasswordPage,
    title: 'metadata.title.arplus.first-access-password',
    description: 'metadata.description.arplus.first-access-password',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_RECOVER_MAIL: {
    LANG_URL: `${langPathRegex}/arplus/recuperar-correo`,
    component: RecoverMailPage,
    title: 'metadata.title.arplus.email-recovery',
    description: 'metadata.description.arplus.email-recovery',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_CREDIT_MILES: {
    LANG_URL: `${langPathRegex}/arplus/acreditar-millas`,
    component: ArplusCreditMilesPage,
    title: 'metadata.title.arplus.credit-miles',
    description: 'metadata.description.arplus.credit-miles',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_GIFT_MILES: {
    LANG_URL: `${langPathRegex}/arplus/regalar-millas`,
    component: ArplusGiftMilesPage,
    title: 'metadata.title.arplus.gift-miles',
    description: 'metadata.description.arplus.gift-miles',
    disallowIndex: true,
    exact: true,
    header: true,
    namespaces: [NAMESPACES.AR_PLUS],
    privateUrl: true
  },
  ARPLUS_RECOVER_EMAIL: {
    LANG_URL: `${langPathRegex}/arplus/recuperar-mail`,
    component: ArplusRecoverEmailPage,
    title: 'metadata.title.arplus.email-recovery',
    description: 'metadata.description.arplus.email-recovery',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_RECOVER_EMAIL_CONFIRMATION: {
    LANG_URL: `${langPathRegex}/arplus/recuperar-mail/confirmacion`,
    component: ArplusRecoverEmailConfirmationPage,
    title: 'metadata.title.arplus.email-confirmation',
    description: 'metadata.description.arplus.email-confirmation',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  ARPLUS_CHANGE_PASSWORD: {
    LANG_URL: `${langPathRegex}/arplus/cambiar-clave`,
    component: ChangePasswordPage,
    title: 'metadata.title.arplus.change-password',
    description: 'metadata.description.arplus.change-password',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  },
  VERIFY_YOUR_ACCOUNT: {
    LANG_URL: `${langPathRegex}/arplus/verifica-tu-cuenta/:token`,
    component: VerifyYourAccountPage,
    title: 'metadata.title.arplus.verify-account',
    description: 'metadata.description.arplus.verify-account',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.AR_PLUS]
  }
}
