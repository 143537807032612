/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import {
  HomePage,
  FlexDatesCalendarPage,
  FlightOffersPage,
  CheckoutPage,
  MyReservationPage,
  SeatsSelectionPage,
  FrequentlyAskedQuestionsPage,
  FrequentlyAskedQuestionsResultsPage,
  FlightStatusPage,
  ExchangesPage,
  LandingPage,
  CommonErrorPage,
  ExchangesPaymentErrorPage,
  CheckoutErrorPage,
  ScheduledFlights,
  SpecialRequestsPage,
  PressCommunicationPage,
  FlightsOffersErrorPage,
  AncillariesPage,
  MyReservationErrorPage,
  CorporateAgreementsPage,
  EventsPage,
  CorporateAgreementsRegisterUserPage,
  BookingPaymentPage,
  PressReleasesPage,
  RefundFormPage,
  RefundStatusPage,
  RefundRequestFormPage,
  PassengersMissingInfo,
  CheckoutGlobalRetry,
  ExternalPayment,
  ReservationServicePage,
  ContestsPage,
  AppLinkRedirect
} from 'pages'

import ARPLUS_ROUTES from 'constants/arplus/routes'
import SUBLOS_ROUTES from 'constants/sublos/routes'
import CONFIG from 'config'
import NAMESPACES from 'localization/constants/namespaces'

// Here langId is not optional, this path regex is used to redirect
// upper case langs to lowercase ones
export const forcelangPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})`

// with this regex matches languageTag format and with ? enables it as an optional parameter
export const langPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})?`

export default {
  HOME: {
    LANG_URL: `${langPathRegex}/`,
    name: 'HOME',
    header: true,
    banner: true,
    component: HomePage,
    disallowIndex: false,
    exact: true,
    namespaces: [NAMESPACES.HOME, NAMESPACES.SEARCH_BOX]
  },
  FLEX_DATES_CALENDAR: {
    LANG_URL: `${langPathRegex}/flex-dates-calendar`,
    header: true,
    component: FlexDatesCalendarPage,
    title: 'metadata.title.flex-dates-calendar',
    description: 'metadata.description.flex-dates-calendar',
    disallowIndex: true,
    exact: true,
    name: 'CALENDAR_OFFERS', // name used for backoffice notification rules
    banner: true,
    namespaces: [NAMESPACES.FLIGHT_OFFERS, NAMESPACES.SEARCH_BOX]
  },
  FLIGHTS_OFFERS: {
    LANG_URL: `${langPathRegex}/flights-offers`,
    header: true,
    banner: true,
    component: FlightOffersPage,
    title: 'metadata.title.flights-offers',
    description: 'metadata.description.flights-offers',
    disallowIndex: true,
    exact: true,
    name: 'FLIGHT_OFFERS',
    namespaces: [NAMESPACES.FLIGHT_OFFERS, NAMESPACES.SEARCH_BOX]
  },
  MY_RESERVATION_ANCILLARIES: {
    LANG_URL: `${langPathRegex}/booking/ancillaries`,
    component: AncillariesPage,
    title: 'metadata.title.booking.ancillaries',
    description: 'metadata.description.booking.ancillaries',
    header: true,
    banner: true,
    disallowIndex: true,
    exact: false,
    name: 'ANCILLARIES',
    namespaces: [NAMESPACES.ANCILLARIES]
  },
  CONTESTS: {
    LANG_URL: `${langPathRegex}/proveedores`,
    name: 'CONTESTS',
    header: true,
    banner: true,
    component: ContestsPage,
    title: 'metadata.title.contests-page',
    description: 'metadata.description.contests-page',
    disallowIndex: true,
    exact: true,
    namespaces: [NAMESPACES.CONTESTS]
  },
  CHECKOUT_ANCILLARIES: {
    LANG_URL: `${langPathRegex}/checkout/ancillaries`,
    component: AncillariesPage,
    title: 'metadata.title.checkout-ancillaries',
    description: 'metadata.description.checkout-ancillaries',
    header: true,
    banner: true,
    disallowIndex: true,
    exact: false,
    name: 'ANCILLARIES',
    namespaces: [NAMESPACES.ANCILLARIES]
  },
  /** @description: needs to be before general checkout steps route so Switch from Router can resolve it*/
  CHECKOUT_RETRY: {
    LANG_URL: `${langPathRegex}/checkout/retry`,
    component: CheckoutGlobalRetry,
    title: 'metadata.title.checkout-retry',
    description: 'metadata.description.checkout-retry',
    disallowIndex: true,
    exact: false,
    header: true,
    banner: true,
    namespaces: [NAMESPACES.PAYMENT]
  },
  CHECKOUT: {
    LANG_URL: `${langPathRegex}/checkout/:step`,
    BASE_PATH: '/checkout',
    component: CheckoutPage,
    title: 'metadata.title.checkout',
    description: 'metadata.description.checkout',
    disallowIndex: true,
    exact: false,
    header: true,
    banner: true,
    namespaces: [NAMESPACES.CHECKOUT]
  },
  MY_RESERVATION: {
    LANG_URL: `${langPathRegex}/booking`,
    header: true,
    banner: true,
    component: MyReservationPage,
    title: 'metadata.title.booking',
    description: 'metadata.description.booking',
    disallowIndex: true,
    exact: true,
    namespaces: [NAMESPACES.MY_RESERVATION]
  },
  MY_RESERVATION_ERROR: {
    LANG_URL: `${langPathRegex}/booking-error`,
    component: MyReservationErrorPage,
    title: 'metadata.title.booking.error',
    description: 'metadata.description.booking.error',
    disallowIndex: true,
    exact: true,
    header: true
  },
  SEATS_SELECTION: {
    LANG_URL: `${langPathRegex}/booking/seats-selection`,
    component: SeatsSelectionPage,
    title: 'metadata.title.seats-selection',
    description: 'metadata.description.seats-selection',
    disallowIndex: true,
    exact: true,
    header: true,
    namespaces: [NAMESPACES.MY_RESERVATION]
  },
  FREQUENTLY_ASKED_QUESTIONS: {
    LANG_URL: `${langPathRegex}/frequently-asked-questions`,
    header: true,
    component: FrequentlyAskedQuestionsPage,
    title: 'metadata.title.frequently-asked-questions',
    description: 'metadata.description.frequently-asked-questions',
    disallowIndex: false,
    exact: true
  },
  FREQUENTLY_ASKED_QUESTIONS_RESULTS: {
    LANG_URL: `${langPathRegex}/frequently-asked-questions-results`,
    header: true,
    component: FrequentlyAskedQuestionsResultsPage,
    title: 'metadata.title.frequently-asked-questions',
    description: 'metadata.description.frequently-asked-questions',
    disallowIndex: true,
    exact: true
  },
  FLIGHT_STATUS: {
    LANG_URL: `${langPathRegex}/flight-status`,
    component: FlightStatusPage,
    title: 'metadata.title.flight-status',
    description: 'metadata.description.flight-status',
    disallowIndex: true,
    header: true,
    exact: true,
    namespaces: [NAMESPACES.FLIGHT_STATUS]
  },
  LANDING: {
    LANG_URL: `${langPathRegex}/preview/:landingId`,
    component: LandingPage,
    header: true,
    banner: true,
    disallowIndex: true,
    exact: false,
    namespaces: [NAMESPACES.SEARCH_BOX]
  },
  EXCHANGES: {
    LANG_URL: `${langPathRegex}/booking/exchanges`,
    component: ExchangesPage,
    title: 'metadata.title.exchanges',
    description: 'metadata.description.exchanges',
    header: true,
    banner: true,
    disallowIndex: true,
    exact: true,
    namespaces: [NAMESPACES.EXCHANGES]
  },
  COMMON_ERROR: {
    LANG_URL: `${langPathRegex}/error`,
    component: CommonErrorPage,
    title: 'metadata.title.common-error',
    description: 'metadata.description.common-error',
    disallowIndex: true,
    exact: true,
    header: true,
    banner: true
  },
  EXCHANGES_ERROR: {
    LANG_URL: `${langPathRegex}/booking/exchanges-error`,
    component: ExchangesPaymentErrorPage,
    title: 'metadata.title.exchanges-error',
    description: 'metadata.description.exchanges-error',
    disallowIndex: true,
    exact: true,
    banner: true
  },
  CHECKOUT_ERROR: {
    LANG_URL: `${langPathRegex}/checkout-error`,
    component: CheckoutErrorPage,
    title: 'metadata.title.checkout-error',
    description: 'metadata.description.checkout-error',
    disallowIndex: true,
    exact: true,
    header: true,
    banner: true
  },
  FLIGHTS_OFFERS_ERROR: {
    LANG_URL: `${langPathRegex}/flights-offers-error`,
    component: FlightsOffersErrorPage,
    title: 'metadata.title.flights-offers-error',
    description: 'metadata.description.flights-offers-error',
    disallowIndex: true,
    exact: true,
    header: true,
    banner: true
  },
  SCHEDULED_FLIGHTS: {
    LANG_URL: `${langPathRegex}/scheduled-flights`,
    header: true,
    component: ScheduledFlights,
    title: 'metadata.title.scheduled-flights',
    description: 'metadata.description.scheduled-flights',
    disallowIndex: true,
    exact: true,
    namespaces: [NAMESPACES.SCHEDULED_FLIGHTS]
  },
  SPECIAL_REQUESTS: {
    LANG_URL: `${langPathRegex}/booking/special-requests`,
    component: SpecialRequestsPage,
    title: 'metadata.title.booking.special-requests',
    description: 'metadata.description.booking.special-requests',
    disallowIndex: true,
    exact: true,
    namespaces: [NAMESPACES.MY_RESERVATION]
  },
  PRESS_COMMUNICATION: {
    name: 'PRESS_COMMUNICATION',
    LANG_URL: `${langPathRegex}/press-communication`,
    component: PressCommunicationPage,
    title: 'metadata.title.press-communication',
    description: 'metadata.description.press-communication',
    disallowIndex: false,
    exact: true
  },
  CORPORATE_AGREEMENTS: {
    LANG_URL: `${langPathRegex}/corporate-agreements`,
    component: CorporateAgreementsPage,
    disallowIndex: false,
    title: 'metadata.title.corporate-agreements',
    description: 'metadata.description.corporate-agreements',
    header: true,
    exact: true,
    banner: true,
    isDisabled: !CONFIG.ENABLE_CORPORATE_AGREEMENTS,
    namespaces: [NAMESPACES.CORPORATE_AGREEMENTS]
  },
  EVENTS: {
    LANG_URL: `${langPathRegex}/congresos`,
    component: EventsPage,
    disallowIndex: false,
    title: 'metadata.title.events',
    description: 'metadata.description.events',
    header: true,
    banner: true,
    exact: true,
    isDisabled: !CONFIG.ENABLE_EVENTS_PAGE,
    namespaces: [NAMESPACES.EVENTS, NAMESPACES.SEARCH_BOX]
  },
  CORPORATE_AGREEMENTS_REGISTER_USER: {
    LANG_URL: `${langPathRegex}/generar-usuario-corporativo`, // not intended to be linked from the app
    component: CorporateAgreementsRegisterUserPage,
    title: 'metadata.title.corporate-agreements-register',
    description: 'metadata.description.corporate-agreements-register',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true,
    isDisabled: !CONFIG.ENABLE_CORPORATE_AGREEMENTS,
    namespaces: [NAMESPACES.CORPORATE_AGREEMENTS]
  },
  BOOKING_PAYMENT_PAGE: {
    name: 'BOOKING_PAYMENT_PAGE',
    LANG_URL: `${langPathRegex}/booking/payment`,
    component: BookingPaymentPage,
    title: 'metadata.title.booking.payment',
    description: 'metadata.description.booking.payment',
    disallowIndex: true,
    header: true,
    exact: true,
    banner: true,
    namespaces: [NAMESPACES.PAYMENT]
  },
  PRESS_RELEASES_PAGE: {
    LANG_URL: `${langPathRegex}/press-releases/:id?`,
    component: PressReleasesPage,
    title: 'metadata.title.press-releases',
    description: 'metadata.description.press-releases',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  REFUND_FORM: {
    LANG_URL: `${langPathRegex}/arrepentimiento-compra`,
    component: RefundFormPage,
    title: 'metadata.title.refund-form',
    description: 'metadata.description.refund-form',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  REFUND_FORM_STATUS: {
    LANG_URL: `${langPathRegex}/estado-de-devolucion`,
    component: RefundStatusPage,
    title: 'metadata.title.refund-status-page',
    description: 'metadata.description.refund-status-page',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  REFUND_REQUEST_FORM: {
    LANG_URL: `${langPathRegex}/reservasservicios/devoluciontuavta`,
    component: RefundRequestFormPage,
    title: 'metadata.title.refund-request-tuavta',
    description: 'metadata.description.refund-request-tuavta',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  RESERVATION_SERVICE_EN: {
    LANG_URL: `${langPathRegex}/reservation-services/:form`,
    component: ReservationServicePage,
    name: 'RESERVATION_SERVICES',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  RESERVATION_SERVICE_ES: {
    LANG_URL: `${langPathRegex}/reservas-servicios/:form`,
    component: ReservationServicePage,
    name: 'RESERVATION_SERVICES',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  RESERVATION_SERVICE_IT: {
    LANG_URL: `${langPathRegex}/prenotazioni-servizi/:form`,
    component: ReservationServicePage,
    name: 'RESERVATION_SERVICES',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  RESERVATION_SERVICE_PT: {
    LANG_URL: `${langPathRegex}/reservas-servicos/:form`,
    component: ReservationServicePage,
    name: 'RESERVATION_SERVICES',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  RESERVATION_SERVICE_FR: {
    LANG_URL: `${langPathRegex}/reserves-servicos/:form`,
    component: ReservationServicePage,
    name: 'RESERVATION_SERVICES',
    disallowIndex: false,
    header: true,
    exact: true,
    banner: true
  },
  PASSENGERS_MISSING_INFO: {
    LANG_URL: `${langPathRegex}/booking/missing-info/:step`,
    component: PassengersMissingInfo,
    title: 'metadata.title.booking.missing-info',
    description: 'metadata.description.booking.missing-info',
    disallowIndex: true,
    header: true,
    exact: false,
    banner: true,
    namespaces: [NAMESPACES.PASSENGERS],
    isDisabled: !CONFIG.ENABLE_PASSENGERS_MISSING_INFO
  },
  EXTERNAL_PAYMENT: {
    LANG_URL: `${langPathRegex}/external-payment/:paymentFlowType`,
    component: ExternalPayment,
    title: 'metadata.title.external-payment',
    description: 'metadata.description.external-payment',
    disallowIndex: true,
    header: true,
    exact: false,
    banner: true,
    namespaces: []
  },
  APP_LINK_REDIRECT: {
    LANG_URL: `${langPathRegex}/applink/:redirectLink`,
    component: AppLinkRedirect,
    title: 'metadata.title.app-link-redirect',
    description: 'metadata.description.app-link-redirect',
    disallowIndex: true,
    header: true,
    exact: false,
    banner: true,
    namespaces: [NAMESPACES.APP_LINK, NAMESPACES.SEO_METADATA]
  },
  ...ARPLUS_ROUTES,
  ...SUBLOS_ROUTES
}
