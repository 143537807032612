import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

import { getAgreementRequestBody } from 'formatters/corporate'

const {
  requestAgreementStart,
  requestAgreementSuccess,
  requestAgreementError,
  requestAgreementClear
} = createActions({
  REQUEST_AGREEMENT_START: () => {},
  REQUEST_AGREEMENT_SUCCESS: data => ({ data }),
  REQUEST_AGREEMENT_ERROR: error => ({ error }),
  REQUEST_AGREEMENT_CLEAR: () => {}
})

const requestCorporateAgreement = formikFields => async dispatch => {
  dispatch(requestAgreementStart())
  const body = getAgreementRequestBody(formikFields)
  try {
    const response = await api.post(apiRoutes.CORPORATE_AGREEMENTS, body)
    dispatch(requestAgreementSuccess(response.data))
  } catch (error) {
    dispatch(requestAgreementError(error.response ? error.response.data : error))
  }
}

const clearCorporateAgreement = () => requestAgreementClear()

export {
  requestCorporateAgreement,
  clearCorporateAgreement,
  requestAgreementStart,
  requestAgreementSuccess,
  requestAgreementError,
  requestAgreementClear
}
