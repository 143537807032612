/* eslint-disable import/no-cycle */
import ARPLUS_ROUTES from 'constants/arplus/routes'
import { FREQUENT_QUESTIONS_URL } from 'constants/frequentQuestions'
import ROUTES, { langPathRegex } from 'constants/routes'
import SUBLOS_ROUTES from 'constants/sublos/routes'
import { shouldNotRenderAnalitycsParams } from 'helpers/utils'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import { generatePathWithLang } from 'localization/helpers'
import SSR_ROUTES from 'server/utils/routes'

export const generateReservationRoute = language =>
  generatePathWithLang(ROUTES.MY_RESERVATION.LANG_URL, language)

export const generateFlexCalendarRoute = language =>
  generatePathWithLang(ROUTES.FLEX_DATES_CALENDAR.LANG_URL, language)

export const generateFlightOffersRoute = language =>
  generatePathWithLang(ROUTES.FLIGHTS_OFFERS.LANG_URL, language)

export const generateFAQRoute = language =>
  generatePathWithLang(
    `${ROUTES.FREQUENTLY_ASKED_QUESTIONS.LANG_URL}${
      shouldNotRenderAnalitycsParams(language) ? FREQUENT_QUESTIONS_URL : ''
    }`,
    language
  )

export const generateFAQResultsRoute = language =>
  generatePathWithLang(
    `${ROUTES.FREQUENTLY_ASKED_QUESTIONS_RESULTS.LANG_URL}${
      shouldNotRenderAnalitycsParams(language) ? FREQUENT_QUESTIONS_URL : ''
    }`,
    language
  )

export const generateHomeRoute = language => generatePathWithLang(ROUTES.HOME.LANG_URL, language)

export const generateExchangesRoute = language =>
  generatePathWithLang(ROUTES.EXCHANGES.LANG_URL, language)

export const generateExchangesErrorRoute = language =>
  generatePathWithLang(ROUTES.EXCHANGES_ERROR.LANG_URL, language)

export const generateFlightOffersErrorRoute = language =>
  generatePathWithLang(ROUTES.FLIGHTS_OFFERS_ERROR.LANG_URL, language)

export const generateCheckoutErrorRoute = language =>
  generatePathWithLang(ROUTES.CHECKOUT_ERROR.LANG_URL, language)

export const generateCommonErrorRoute = language =>
  generatePathWithLang(ROUTES.COMMON_ERROR.LANG_URL, language)

export const generateCheckoutRoute = (language, step) =>
  generatePathWithLang(ROUTES.CHECKOUT.LANG_URL, language, { step })

export const generateCheckoutAncillariesRoute = language =>
  generatePathWithLang(ROUTES.CHECKOUT_ANCILLARIES.LANG_URL, language)

export const generateMyReservationErrorRoute = language =>
  generatePathWithLang(ROUTES.MY_RESERVATION_ERROR.LANG_URL, language)

export const generateMyReservationAncillariesRoute = language =>
  generatePathWithLang(ROUTES.MY_RESERVATION_ANCILLARIES.LANG_URL, language)

export const generateMyReservationRoute = language =>
  generatePathWithLang(ROUTES.MY_RESERVATION.LANG_URL, language)

export const generateArPlusRoute = language =>
  generatePathWithLang(ROUTES.ARPLUS.LANG_URL, language)

export const generateFlightStatusRoute = language =>
  generatePathWithLang(ROUTES.FLIGHT_STATUS.LANG_URL, language)

export const generateCorporateAgreementsRoute = language =>
  generatePathWithLang(ROUTES.CORPORATE_AGREEMENTS.LANG_URL, language)

export const generateSpecialRequestsRoute = language =>
  generatePathWithLang(ROUTES.SPECIAL_REQUESTS.LANG_URL, language)

export const generateSeatSelectionRoute = language =>
  generatePathWithLang(ROUTES.SEATS_SELECTION.LANG_URL, language)

export const generatePressCommunicationRoute = language =>
  generatePathWithLang(ROUTES.PRESS_COMMUNICATION.LANG_URL, language)

export const generateBookingPaymentRoute = language =>
  generatePathWithLang(ROUTES.BOOKING_PAYMENT_PAGE.LANG_URL, language)

export const generateGlobal3pRedirectRoute = language =>
  generatePathWithLang(SSR_ROUTES.GLOBAL_3P_PAYMENT.URL, language)

export const generateMercadoPagoRedirectRoute = language =>
  generatePathWithLang(SSR_ROUTES.MERCADO_PAGO.URL, language)

export const generatePressReleasesRoute = language =>
  generatePathWithLang(ROUTES.PRESS_RELEASES_PAGE.LANG_URL, language)

export const generatePressReleaseRedirectRoute = (language, id) =>
  generatePathWithLang(ROUTES.PRESS_RELEASES_PAGE.LANG_URL, language, { id })

export const generateNoMatchRoute = language =>
  generatePathWithLang(`${langPathRegex}/page-not-found`, language)

export const generateCheckoutRetryRoute = language =>
  generatePathWithLang(ROUTES.CHECKOUT_RETRY.LANG_URL, language)

export const generatePassengersMissingInfoRoute = (language, step) =>
  generatePathWithLang(ROUTES.PASSENGERS_MISSING_INFO.LANG_URL, language, { step })

export const generateExternalPaymentRoute = (language, paymentFlowType) =>
  generatePathWithLang(ROUTES.EXTERNAL_PAYMENT.LANG_URL, language, { paymentFlowType })

export const generateArPlusLoginRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_LOGIN.LANG_URL, language)

export const generateArPlusRegisterRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_REGISTER.LANG_URL, language)

export const generateArPlusPreRegisterRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_PRE_REGISTER.LANG_URL, language)

export const generateArPlusFirstAccessRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_FIRST_ACCESS.LANG_URL, language)

export const generateArPlusFirstAccessSetPasswordRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_FIRST_ACCESS_SET_PASSWORD.LANG_URL, language)

export const generateArPlusRecoverEmailRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_RECOVER_EMAIL.LANG_URL, language)

export const generateArPlusRecoverEmailConfirmationRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_RECOVER_EMAIL_CONFIRMATION.LANG_URL, language)

export const generateArPlusForgottenPasswordRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_FORGOTTEN_PASSWORD.LANG_URL, language)

export const generateArPlusRecoverMembershipCodeRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_RECOVER_MEMBERSHIP_CODE.LANG_URL, language)

export const generateArPlusMyAccountRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_MY_ACCOUNT.LANG_URL, language)

export const generateElitePartnersRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_ELITE_PARTNERS.LANG_URL, language)

export const generateArPlusDashboardRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_DASHBOARD.LANG_URL, language)

export const generateArPlusLastOperations = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_LAST_OPERATIONS.LANG_URL, language)

export const generateArPlusNewPassword = (language, membershipCode) =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_FORGOTTEN_PASSWORD_CODE.LANG_URL, language, {
    membershipCode
  })

export const generateArPlusResetPassword = (language) =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_CHANGE_PASSWORD.LANG_URL, language)

export const generateArplusAccountEvaluationRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_ACCOUNT_EVALUATION.LANG_URL, language)

export const getArplusAccountDataRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_ACCOUNT_DATA.LANG_URL, language)

export const getArplusRecoverEmailRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_RECOVER_MAIL.LANG_URL, language)

export const generateArplusCreditMilesRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_CREDIT_MILES.LANG_URL, language)

export const generateLandingRoute = (language, landingId) =>
  generatePathWithLang(ROUTES.LANDING.LANG_URL, language, { landingId })

export const generateReservationServiceRoute = (language, form) => {
  const languageCode = language.slice(0, 2)
  switch (languageCode) {
    case LANGUAGE_TAGS.ENGLISH_ONLY_LANG:
      return generatePathWithLang(ROUTES.RESERVATION_SERVICE_EN.LANG_URL, language, { form })
    case LANGUAGE_TAGS.DEFAULT_ONLY_LANG:
      return generatePathWithLang(ROUTES.RESERVATION_SERVICE_ES.LANG_URL, language, { form })
    case LANGUAGE_TAGS.ITALIAN_ONLY_LANG:
      return generatePathWithLang(ROUTES.RESERVATION_SERVICE_IT.LANG_URL, language, { form })
    case LANGUAGE_TAGS.PORTUGUESE_ONLY_LANG:
      return generatePathWithLang(ROUTES.RESERVATION_SERVICE_PT.LANG_URL, language, { form })
    case LANGUAGE_TAGS.FRENCH_ONLY_LANG:
      return generatePathWithLang(ROUTES.RESERVATION_SERVICE_FR.LANG_URL, language, { form })
    default:
      return null
  }
}

export const generatePaymentMilesRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_PAYMENT_MILES.LANG_URL, language)

export const generateArPlusTransferMilesRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_TRANSFER_MILES.LANG_URL, language)

export const generateArPlusGiftMilesRoute = language =>
  generatePathWithLang(ARPLUS_ROUTES.ARPLUS_GIFT_MILES.LANG_URL, language)

export const generateSublosHomeRoute = language =>
  generatePathWithLang(SUBLOS_ROUTES.SUBLOS_HOME.LANG_URL, language)

export const generateSublosLoginRoute = language =>
  generatePathWithLang(SUBLOS_ROUTES.SUBLOS_LOGIN.LANG_URL, language)

export const generateSublosErrorRoute = language =>
  generatePathWithLang(SUBLOS_ROUTES.SUBLOS_ERROR.LANG_URL, language)

export const generateSublosWebsiteInProgressRoute = language =>
  generatePathWithLang(SUBLOS_ROUTES.SUBLOS_WEBSITE_IN_PROGRESS.LANG_URL, language)
