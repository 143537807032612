/* eslint-disable import/no-cycle */
import CONFIG from 'config'
import { parseRichText } from 'helpers/cms'
import { withExternalLink } from 'helpers/richText'

import { decodeURLParams } from '../../helpers/url/cms'
import { mapSlider } from './slider'

/**
 * Groups data based on the presence of the 'card_image' property to be used in Tabs.
 * @param {Array<Object>} data - The array of objects to be grouped.
 * @returns {Array<Array<Object>>} An array of arrays,
 * where each inner array contains objects with or without 'card_image' property.
 */
const groupDataFromCms = data => {
  return data.reduce((accumulator, currentValue) => {
    if (currentValue.card_image) {
      const lastGroup = accumulator[accumulator.length - 1]
      if (lastGroup && lastGroup[0].card_image) {
        lastGroup.push(currentValue)
      } else {
        accumulator.push([currentValue])
      }
    } else {
      accumulator.push([currentValue])
    }
    return accumulator
  }, [])
}

const mapRichText = item => ({
  text:
    item.rich_text &&
    withExternalLink(item.rich_text.replace(/src="/g, `src="${CONFIG.CONTENT_CDN_BASE_URL}`)),
  menuId: item.rich_text_attached_menu && item.id
})

const mapCardImage = item => ({
  id: item.id,
  title: item.title,
  image: CONFIG.CONTENT_CDN_BASE_URL + item.card_image,
  description: withExternalLink(item.card_description),
  alt: item.card_alt_text,
  url: decodeURLParams(item.card_url)
})

const mapGalleryItem = (item, containerItem) => ({
  id: item.id,
  title: item.title,
  alt: item.gallery_altern_text,
  description: withExternalLink(item.gallery_description),
  image: CONFIG.CONTENT_CDN_BASE_URL + item.gallery_image,
  menuId: containerItem.gallery_attached_menu && containerItem.id
})

export const mapCollapsibleItem = item => ({
  icon: item.collapsible_icon && CONFIG.CONTENT_CDN_BASE_URL + item.collapsible_icon,
  title: item.title,
  menuId: item.collapsible_attached_menu && item.id,
  id: item.id,
  components: (item.collapsible_reference.components_reference || []).map(col => ({
    richText: {
      ...mapRichText(col)
    },
    galleryItem: (col.gallery_item_reference || []).map(item => mapGalleryItem(item, col))
  }))
})

export const mapTabItem = (item, tab) => ({
  menuId: item.tab_attached_menu && item.id,
  id: tab.id,
  title: tab.title,
  icon: tab.tabs_icon && CONFIG.CONTENT_CDN_BASE_URL + tab.tabs_icon,
  url: tab.tabs_url,
  panel: groupDataFromCms(
    (Array.isArray(tab.tabs_reference) && tab.tabs_reference) || Object.values(tab.tabs_reference)
  ).map(panel => ({
    richText: {
      ...mapRichText(panel[0])
    },
    card: panel[0].card_image && panel.map(card => mapCardImage(card)),
    galleryItem: (panel[0].gallery_item_reference || []).map(item =>
      mapGalleryItem(item, panel[0])
    ),
    collapsible: panel[0].collapsible_reference && mapCollapsibleItem(panel[0])
  }))
})

export const mapLanding = data => ({
  id: data.nid,
  title: data.title,
  withSearchBox: data.landingWithSearchBox,
  url: decodeURLParams(data.landingPageUrl),
  campaignId: data.landingCampaignId,
  sections: data.landingSectionReference.map(item => ({
    title: item.title,
    nodeId: item.id,
    richText: {
      text: withExternalLink(parseRichText(item.rich_text)),
      menuId: item.rich_text_attached_menu && item.id
    },
    card: item.card_image && mapCardImage(item),
    galleryItem: item.gallery_item_reference &&
      (
        (Array.isArray(item.gallery_item_reference) && item.gallery_item_reference) ||
        Object.values(item.gallery_item_reference)
      ).map(({ id, gallery_altern_text, gallery_description, gallery_image, title }) => ({
        id,
        title,
        alt: gallery_altern_text,
        description: withExternalLink(gallery_description),
        image: CONFIG.CONTENT_CDN_BASE_URL + gallery_image,
        menuId: item.gallery_attached_menu && item.id
      })),
    leftColumn: (item.left_panel_reference || []).map(col => ({
      menuId: item.columns_attached_menu && item.id,
      video: {
        url: decodeURLParams(col.video_url),
        menuId: col.video_attached_menu && col.id
      },
      title: col.title,
      richText: {
        text:
          col.rich_text &&
          withExternalLink(col.rich_text.replace(/src="/g, `src="${CONFIG.CONTENT_CDN_BASE_URL}`)),
        menuId: col.rich_text_attached_menu && col.id
      },
      image: {
        src: col.columns_image_image,
        alt: col.columns_image_alt_text
      },
      widthSize: item.left_panel_width_size
    })),
    rightColumn: (item.right_panel_reference || []).map(col => ({
      video: {
        url: decodeURLParams(col.video_url),
        menuId: col.video_attached_menu && col.id
      },
      title: col.title,
      richText: {
        text:
          col.rich_text &&
          withExternalLink(col.rich_text.replace(/src="/g, `src="${CONFIG.CONTENT_CDN_BASE_URL}`)),
        menuId: col.rich_text_attached_menu && col.id
      },
      image: {
        src: col.columns_image_image,
        alt: col.columns_image_alt_text
      },
      widthSize: item.right_panel_width_size
    })),
    tabs: (
      (Array.isArray(item.tabs_container_reference) && item.tabs_container_reference) ||
      Object.values(item.tabs_container_reference || {})
    ).map(tab => mapTabItem(item, tab)),
    video: {
      url: decodeURLParams(item.video_url),
      menuId: item.video_attached_menu && item.id,
      videoAlignment: item.video_alignment,
      width: item.video_width && `${item.video_width.replace('.', '')}px`,
      height: item.video_height && `${item.video_height.replace('.', '')}px`
    },
    imageGrid: item.image_grid_reference && {
      title: item.title,
      menuId: item.image_grid_attached_menu && item.id,
      images: (item.image_grid_reference || []).map(img => ({
        image: CONFIG.CONTENT_CDN_BASE_URL + img.image_grid_item,
        alt: img.image_grid_item_alt_text,
        url: decodeURLParams(img.image_grid_item_url),
        description: withExternalLink(img.image_grid_description)
      }))
    },
    collapsible: item.collapsible_reference && mapCollapsibleItem(item),
    // Helps avoid crashing when comes from cms as an object.
    slider: item.slider_image_reference && {
      title: item.title,
      menuId: item.slider_image_attached_menu && item.id,
      images: (
        (Array.isArray(item.slider_image_reference) && item.slider_image_reference) ||
        Object.values(item.slider_image_reference || {})
      ).map(slider => ({
        ...mapSlider(slider),
        description: withExternalLink(slider.slider_image_description)
      }))
    },
    infoSections: (item.info_item_reference || []).map(section => ({
      menuId: section.info_item_attached_menu && section.id,
      description: withExternalLink(
        section.info_item_description.replace(/src="/g, `src="${CONFIG.CONTENT_CDN_BASE_URL}`)
      ),
      title: section.title,
      showTitle: section.info_item_title,
      buttonText: section.info_item_button,
      url: decodeURLParams(section.info_item_url),
      image: CONFIG.CONTENT_CDN_BASE_URL + section.info_item_image,
      alternText: section.info_item_alt_text
    })),
    banner: item.header_image && {
      id: data.nid,
      image: CONFIG.CONTENT_CDN_BASE_URL + item.header_image,
      title: item.title,
      alt: item.header_image_alt_text,
      description: withExternalLink(item.header_image_description),
      button: item?.header_image_button_text,
      url: decodeURLParams(item.header_image_button_link)
    },
    reservationSearchBox: (item.reservation_btn_act || item.reservation_btn_text) && {
      id: item.id,
      title: item.title,
      label: item.reservation_btn_text,
      action: item.reservation_btn_act
    },
    iframeForm: item.iframe_url && {
      id: item.id,
      title: item.title,
      url: decodeURLParams(item.iframe_url),
      height: item.iframe_height
    }
  }))
})
